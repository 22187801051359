.datatables-liste {
    padding: 20px 0;
    .datatable-separateur{
        padding: 8px;
        vertical-align: top;
        border-top: 1px solid #ddd;
        cursor: pointer;
                b {
            font-size : 1.25em;
            line-height: 1.6;
        }
        .bouton-formation{
            float: right;
            padding: 5px 10px;
            margin: 0;
            width: 30px;
        }
    }
    #table-datatables-prof {
        display: table;
    }

    #table-exercices {
        display: table;
    }

    .dataTables_wrapper{
        padding: 20px 0;
        .col-sm-5 {
            width: 41.6666666667%;
        }
        .col-sm-6 {
            width: 50%;
        }
        .col-sm-7 {
            width: 58.3333333333%;
        }
        .col-sm-12 {
            width: 100%;
        }
    }

    .table-datatables {
        display: none;
        padding: 10px 0;
        tr{
            &.datatable{
                border : {
                    left : 6px solid transparent;
                }
                .btn-download-datatable{
                    background: $blue;
                    color: white;
                    border : {
                        color: $blue;
                    }
                }
                &.downloaded{
                    border : {
                        color:  $blue;
                    }
                    .btn-download-datatable{
                        background: white;
                        color: $blue;
                        border : {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
}
