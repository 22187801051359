.e-learning {
    .identifiant-elearning {
        color: #3c763d;

        i {
            font-size: 30px;
            padding-right: 15px;
        }

        div {
            display: inline-block;
            vertical-align: middle;

            .identifiant {
                font-size: 20px;
                margin-bottom: 0;
            }
        }
    }
    .need-toeic-container {
        .alert-info {
            margin-bottom: 0px;
            i {
                font-size: 30px;
                padding-right: 15px;
            }
        }
        .text-right-icon {
            display: inline-block;
            vertical-align: middle;
            width: 82%;
            a {
                text-decoration: underline;
            }
        }

        .retour-dmd-inscription {
            margin-top: 10px;
        }
    }

    @media only screen and (min-width: 992px) {
        .need-toeic-container {
            .text-right-icon {
                width: 90%;
            }
        }
    }
}
