$couleurFacebook: #3e5b98;
$couleurTwitter: #4da7de;
$couleurLinkedin: #3371b7;
$couleurYoutube: #e02a20;
$couleurViadeo: #e4a000;

.footer .fa {
	color: white;
}

.fa-facebook {
	background: $couleurFacebook;
}
.fa-twitter {
	background: $couleurTwitter;
}
.fa-linkedin {
	background: $couleurLinkedin;
}
.fa-youtube {
	background: $couleurYoutube;
}
.fa-viadeo {
	background: $couleurViadeo;
}