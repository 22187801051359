.logiciels-tabs {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #e1e1e1;
    margin-top: 15px;

    button {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;

        &:hover {
            background-color: #ddd;
        }
    }

    .active {
        background-color: #ccc;
    }
}

.logiciels-tab-content {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

// Lockimmo
#filter-inscrit {
    margin: 20px 5px 20px 0;
    width: 20%;
    display: inline;
    height: 36px;
}

.rechercher {
    padding: 8px 15px;
    text-align: center;
}
