/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 4 mai 2016, 10:42:55
    Author     : l.rouquette
*/
header{
	&.connexion{
		color: #235981;
		font-size: 20px;
	}
}
.content
{
    padding: 15px;
    margin-top: 25px;
    margin-bottom: 30px;

    button
    {
        margin: auto;
    }
}

.connexion-row{
	background : url('../../img/connexion.jpg') center center no-repeat;
	padding: 20px 0px;
	.connexion-box{
		padding: 10px 40px;
		text-align: center;
		h2{
			margin-top: 5px;
		}
		input{
			border-radius: 0px;
			margin-bottom: 10px;
		}
		input[type="submit"]{
			padding: 10px;
			position: relative;
		}
	}
}

.advert{
	.video{
		padding: 30px;
		.embed{
			box-shadow :1px 1px 5px #FFFFFF,
						-1px 1px 5px #FFFFFF,
						1px -1px 5px #FFFFFF,
						-1px -1px 5px #FFFFFF;
		}
	}
}

#logoEFC-connexion{
	width: 300px;
}