.btn-principal{
	background: $blue-dark-2;
	color: white;
	&:hover{
		color: white;
		background: lighten($blue-dark-2, 10%);
	}
}

.btn-click-to-unlock {
	position: relative;
	z-index: 1;
	.unlocked{
		position: absolute;

		height: 100%;
		top: 0;
		left: 100%;
		// display: none;
		background: $brand-danger;
		z-index: 5;

		border-radius: 0 5px 5px 0;
		visibility: hidden;
		width: 0;
		overflow: hidden;
		transition: width 0.5s ease;
	}

	&:focus{
		.unlocked{
			// display: block;
			visibility: visible;
			width: 100px;
			padding: 5px;
			border: 1px solid darken($brand-danger, 10%);
		}
	}
}
.btn-action{
	cursor: pointer;
}

.push-vertical{
	margin: 10px 0 !important;
}

// Bouton spinner
.fa-circle-o-notch.fa-spin {
	font-size: 15px;
	padding: 0;
}
