$widthContainerMax : 1170px;
$widthContainerMid : 970px;
$widthContainerMin : 750px;
$widthSiderRight : 265px;

$couleurTexte : #383a4c; /* Identique _colors->$dark-blue-2 */

*:focus {
    outline: none;
}

*:active {
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.content:first-child {
    margin-top: 5px;
}

.btn:active {
    outline: none !important;
}

.btn.white:active {
    border: none !important;
    margin: 1px;
}

.btn:focus {
    outline: none !important;
}

.margin-15 {
    margin:15px !important;
}

.margin-top-15 {
    margin-top:15px !important;
}

.margin-bottom-15 {
    margin-bottom:15px !important;
}

.margin-bottom-30 {
    margin-bottom:30px !important;
}




.margin-5 {
    margin:5px !important;
}

.margin-top-5 {
    margin-top:5px !important;
}

.margin-bottom-5 {
    margin-bottom:5px !important;
}




bold, .bold {
    font-weight: 600;
}

a {
    color: inherit;
}

h1 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2.7rem;
}

h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2rem;
}

h3 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
}

h4 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
}

h5 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size : 1.1rem;
}

p {
    font-size : 1rem;
}

li {
    font-size: 1rem;
}

body, html
{
    color : $couleurTexte;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    margin: 0;
}

html {
    font-size: 14px;
}

.small {
    font-size:12px;
}

.a-like {

    background-color: white;
    display: inline-block;
    width: 100%;
    border: none;

}

.a-like:hover {
    background-color: rgb( 245,245,245 );
}

.underline {
    text-decoration: underline;
}

html
{
    min-height: 100vh;
}

body {
    background-color: inherit;
}

#myModalVersion .modal-body {
    text-align: center;
}

// .container
// {
//     min-height: calc( 100vh - 235px );
// }

.container {
    // background-color: inherit;
    // position: relative;
    // display: inline-block;
    // margin-right: 0;

    > .alert {
        margin-bottom: 5px;
    }
}

.content {
    background-color: white;
    border-bottom : 1px solid #E5E5E5;
    padding: 5px;
    margin-bottom: 25px;
    position: relative;
}

.desktop {
    display: block !important;
}

.phone {
    display: none !important;
}

a.noStyle
{
    color: #000000;
    text-decoration: none !important;
}

button.noStyle
{
    background: none;
    border: none;
    padding: 0;
}

a.noStyle:hover
{
    text-decoration: none !important;
}



.bouton-dual-color-dark {
    background: linear-gradient(#575968 50%, #383A4C 50%);
    border-radius: 5px;
    display: inline-block;
    border: none;

    a {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.bouton-dual-color-dark:hover {
    background: #383A4C;
    cursor: pointer;
}

.bouton-dual-color-light {
    background: linear-gradient(#fbfbfc 50%, #f0f1f4 50%);
    border: 1px solid $grey-light-2;
    border-radius: 5px;
    display: inline-block;

    a {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.bouton-dual-color-light:hover {
    background: #fbfbfc;
    cursor: pointer;
}

.bouton.bouton-large {
    width: 137px;
    height: 42px;

    vertical-align: middle;
    text-align: left;

    i {
        font-size: 20px;
        margin-right: 7px;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 8px 5px 0 10px;
    }
}

.bouton.bouton-large-no-icon {
    width: 137px;
    height: 42px;
    vertical-align: middle;
    text-align: center;

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 7px 5px;
    }
}

.bouton.bouton-xlarge {
    width: 190px;
    height: 42px;
    vertical-align: middle;

    i {
        font-size: 20px;
        margin-right: 7px;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 7px 5px 0 10px;
    }
}

.bouton.bouton-petit {
    width: 42px;
    height: 32px;
    vertical-align: middle;

    i {
        font-size: 20px;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 5px;
    }
}

// permet le design des boutons, avec chacun des cas rencontrés
.btn-base {
    text-align: left;

    i {
        padding-left:10px;
    }

    i.font {
        padding:4px 0px !important;
    }

    i.phone {
        padding-left:0px !important;
    }

    span.padding {
        position:relative;
        top:8px;
    }

    span.padding-min {
        position:relative;
        top:7px;
    }
}

button {
    .large-clic {
        width:100%;
        height:100%;
        padding:10px 16px;
    }

    .logo {
        margin:0px;
    }

    .lien:hover {
        color:#23527c;
    }

    button.align-center {
        text-align:center !important;
    }

    span.padding-mini {
        position:relative;
        top:6px;
    }

}

button.align-center {
    text-align:center !important;
}

.bouton.contenu-large {
    padding-top: 0;
    text-align: left;

    i{
        margin: 11px 15px 0 0;
        float: left;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 0;
    }
}


.checkbox label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr {
  opacity: .5;
}

.no-border {
    border: none;
}

.sider-right
{
    width: $widthSiderRight;
    display: inline-block;
    vertical-align: top;
}

.nohover {
    cursor: default !important;
    background-color: white;
}

.nohover:hover {
    cursor: default !important;
    background-color: white;
    border-color: #CCC;
}

.popin-title, .popin-content {
    display: none;
}

.popin .modal-title {
    text-align: center;
}

ol.breadcrumb {
    background-color: white;
    border: 1px solid white;
    display: inline-block;
    margin-bottom: 0;
    margin-top:5px;
}


.container-central{
    max-width: calc(1170px + 295px);
    margin: {
        right : auto;
        left: auto;
    }
    @media only screen and (min-width: 992px) {
        & > div:first-child:nth-last-child(2), & > div:nth-last-child(2) {
            float: left;
        }
    }
}
.inline-block{
    display: inline-block;
}
.fl-left{
    float: left;
}

.ui-selectmenu-open{
    border: solid 1px #b5a8a8;
    & ul{
        max-height: 410px;
    }
}

.ui-selectmenu-button{
    width: 100% !important;
}


.material-switch {
    display: inline-block;
}

.material-switch > input[type="checkbox"] {
    display: none;
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}


@media only screen and (min-width: 1440px) {
    // .container {
    //     width: $widthContainerMax;
    //     margin-left: calc((100% - #{$widthContainerMax} - #{$widthSiderRight} - 20px) / 2);
    // }
    .sider-right {
        display: inline-block;
    }

    #headerDesktop #top {
        width: calc(#{$widthContainerMax} + #{$widthSiderRight});
    }
}

/* Large Devices, Wide Screens (1200 < screen < 1440) [md] */
@media only screen and (max-width: 1440px) {
    html {
        font-size : 14px;
    }
    // .container {
    //     display: block;
    //     margin-left: auto;
    //     margin-right: auto;
    // }
    .sider-right {
        // display: block;
        width: $widthContainerMax;
        margin-left: auto;
        margin-right: auto;
        padding: 0 15px;
    }

    #headerDesktop #top {
        width: calc(#{$widthContainerMax} - 30px);
    }
}

/* Large Devices, Wide Screens (992 < screen < 1200) [md] */
@media only screen and (max-width: 1200px) {
    html {
        font-size : 14px;
    }

    #headerDesktop #top {
        width: calc(#{$widthContainerMid} - 30px);
    }

    .sider-right {
        width: $widthContainerMid;
    }
}

/* Ecran en dessous de 1024 (screen < 1024) */
@media only screen and (max-width: 1024px) {
    html {
        font-size : 14px;
    }


}
/* Medium Devices, Desktops (768 < screen < 992) [sm] */
@media only screen and (max-width: 992px) {
    html {
        font-size : 14px;
    }

    // .container {
    //     width: 100%;
    //     padding: 0;
    //     margin: 0;
    //     $widthContainer : 100%;
    // }

    .content {
        width: 100%;
        padding: 5px;
        margin: 0 0 25px 0;
    }

    .content:first-child {
        margin-top: 10px;
    }

    .desktop {
        display: none !important;
    }

    .phone {
        display: block !important;
    }

    #headerDesktop #top {
        width: $widthContainerMin;
    }

    .sider-right {
        width: 100%;
        padding: 0;
    }

    .contenu-aide {
        display: none !important;
    }
}
/* Small Devices, Tablets (480 < screen < 768) [xs] */
@media only screen and (max-width: 768px) {
    html {
        font-size : 14px;
    }

    // .container {
    //     width: 100%;
    //     padding: 0;
    //     margin: 0;
    //     $widthContainer : 100%;
    // }

    .content {
        width: 100%;
        padding: 30px 0;
        margin: 0 0 25px 0;
    }

    .content:first-child {
        margin-top: 10px;
    }
}
/* Extra Small Devices, Phones (320 < screen < 480) */
@media only screen and (max-width: 480px) {
    html {
        font-size : 12px;
    }

    // !!!!!!!!!!!!! Attention !!!!!!!!!!!!!!!!!!!!!! \\
    // !!!!!!!!!!!!! impact bootstrap !!!!!!!!!!!!!!! \\
    // !!!!!!!!!!!!! Attention !!!!!!!!!!!!!!!!!!!!!! \\
    .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
    }

    .main-content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .st-pusher {
        padding-bottom:200px !important;
    }

    // .container {
    //     width: 100%;
    //     padding: 0;
    //     margin: 0;
    // }

    .content {
        width: 100%;
        padding: 30px 0;
        margin: 0 0 25px 0;
    }

    .content:first-child {
        margin-top: 10px;
    }
}

@media print {
    /* on modal open bootstrap adds class "modal-open" to body, so you can handle that case and hide body */
    body *:not(.clearfix) {
        transform: translate(0) !important;
        background-color: #fff !important;
        color: #000 !important;
    }
    body.modal-open {
        background-color: #fff !important;
    }
    body.modal-open * {
        display: none;
        background-color: #fff !important;
        color: #000 !important;
    }
    body.modal-open {
        visibility: hidden;
    }

    body.modal-open .modal-dialog {
        width: 100%;
        margin: 0;
    }

    body.modal-open .modal .modal-header,
    body.modal-open .modal .modal-body {
        visibility: visible; /* make visible modal body and header */
        padding: 0px;
    }

    body .modal * {
        display: inherit;
    }
    body .modal {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0px;
        overflow: visible!important;
        width: 100%;
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
    html {
        font-size : 12px;
    }
}
