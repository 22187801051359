.formulaire-test-auto-corrige
{
    padding: 5px;

    .reponse
    {
        border-collapse: collapse;
        float: left;
        width: 100%;
        max-width: 500px;
    }

    .reponse:nth-child(odd)
    {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }

    .reponse:nth-child(even)
    {
        border-left: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        width: 50px;
    }

    // .btn-retour-test
    // {
    //     margin-bottom:20px;
    //     margin-left:30px;
    // }


}

.image-test-auto-corrige-form
{
    max-width:800px;
    max-height: 600px;
    padding:15px 0px;
    display:inline-block;

}


.legende
{
    width:73%;
    margin-left:auto;
    margin-right:auto;
    padding:0px 30px;

    h3
    {
        text-align:center;
    }

    .checkbox-test-auto-corrige
    {
        padding-left:10px;
    }
}

.container-test-auto-corrige
{
    margin-left:50px;
    .image-test-auto-corrige
    {
        max-width:800px;
        max-height: 600px;
        padding:15px 0px;
    }

    .text-correction
    {
        margin-left:10px;
    }

    a{
        color: #337ab7;
    }
}

@media only screen and (max-width: 1024px) {
    .container-test-auto-corrige
    {
        margin-left:0px;
        .image-test-auto-corrige
        {
            max-width:98%;
        }
    }
}

@media only screen and (max-width:680px){
    .legende
    {
        width:100%;
        padding:0px;
    }
}