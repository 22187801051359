.dossier_eleve {

	.bouton.rechercher {
		padding: 8px 15px;
		text-align: center;
	}

	.bouton.rechercher-autre-eleve {
		width: 210px;
		text-align: center;
		height: 30px;
		padding-top: 4px;
		margin-top: 5px;
	}

	.eleve {
		margin-left: 30px;
	}

	.eleve-input {
		margin: 20px 0;
	}

	li {
		margin-bottom: 10px;
	}

	.fa-chevron-down {
		float: right;
	}

	.infos-eleve {
		padding-left: 40px;

		.left {
			display: inline-block;
			width: 80%;
			vertical-align: top;

			.nom-eleve {
				font-size: 25px;
				font-weight: bold;
			}

			.telephone {
				font-size: 20px;
			}

			.space {
				margin: 0 10px;
			}
		}

		.right {
			display: inline-block;
			width: 19%;
			vertical-align: top;
			text-align: center;
			padding-right: 20px;

			.avatar-eleve {
				width: 175px;
				height: 175px;
			}

			.connexion-eleve {
				margin-top: 20px;
			}
		}
	}

	.observations {
		> h3 {
			padding-left: 40px;
		}

		.contenu-observations-wrapper {
			padding: 20px 0;

			.contenu-observations {
				width: 95%;
				margin-left: auto;
				margin-right: auto;
				border: 1px solid lightgrey;

				.observation {
					.date {
						width: 8%;
					}
					.type-suivi{
						width: 15%;
					}
					.type-note {
						width: 15%;
					}
					.texte-observation {
						width: 62%;
					}
				}

				#tableau-observation_length {
					margin-left: 20px;
				}

				#tableau-observation_filter{
					margin-right: 20px;
				}

				div.dataTables_wrapper div.dataTables_paginate {
					text-align: left;
					margin-top: 10px;
				}

				.deposer-observation {
					padding: 20px;
					border-bottom: 1px solid lightgrey;

					.left {
						width: 75%;
						display: inline-block;
						vertical-align: top;

						.observation-input {
							height: 150px;
						}
					}

					.right {
						width: 24%;
						display: inline-block;
						vertical-align: top;
						padding-left: 20px;

						.bouton {
							margin-top: 5px;
							padding: 8px 15px;
							text-align: center;
						}
					}

				}

				.observation:nth-child(even) {
					background: #F3F3F3;
				}
			}
		}
	}

	.avancement-devoirs {
		
		> h3 {
			padding-left: 40px;
		}

		.contenu-avancement-devoirs-wrapper {
			padding: 20px 0;

			.table-avancement-devoirs {
				margin: 10px auto;
			}
		}
	}

	.appareils-mobiles {

		> h3 {
			padding-left: 40px;
		}

		> h4 {
			padding-left: 60px;
			margin: 15px 0;
		}
		
		.table-appareils {
			width: 80%;
			margin-left: auto;
			margin-right: auto;
			text-align: center;

			.row.titres {
				font-weight: bold;
				margin-bottom: 20px;
			}

			.row.appareil {
				margin-bottom: 10px;
			}
		}

		.quotas {
			padding-left: 80px;

			.modification-quota {
				display: inline-block;

			}

			.formulaire-modification-quota {
				display: inline-block;
				margin-left: 20px;

				.input-quota {
					width: 65px;
					display: inline-block;
				}

				.bouton {
					margin-left: 20px;
					padding: 8px 15px;
					text-align: center;
					vertical-align: top;
				}
			}
		}
	}

	.login-eleve {

		.infos-login-eleve {
			padding-left: 20px;

			> p {
				margin: 0;
				padding: 10px 0;
			}

			.ouvrir-aide {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: block;
				text-align: center;
			}
		}
	}

	.inscriptions {

		.inscription.row {
			padding: 10px 0 10px 20px
		}
	}
}
