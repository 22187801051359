#paiement-en-ligne {

    p {
        margin: 14px 0;
    }

    label {
        font-weight: normal;
    }

    input[type=checkbox] {
        width: 18px;
        height: 18px;
        cursor: pointer;
        accent-color: #5cb85c;
    }

    input[type=number] {
        border-radius: 4px;
        border: 1px solid;
        text-align: center;
        margin: 5px 2px;
    }

    input[type=checkbox]:disabled {
        cursor: not-allowed;
    }

    // Hide Arrows From Input Number
    // Chrome, Safari, Edge, Opera
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    // Firefox
    input[type=number] {
        -moz-appearance: textfield;
    }

    .partial {
        accent-color: #ffa500 !important;
    }

    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0;
    }

    // bordure arrondi pour les tableaux
    td, th {
        border: solid 1px #bbbbbb;
        border-style: none solid solid none;
    }

    tr:first-child th:first-child {
        border-top-left-radius: 4px;
    }
    tr:first-child th:last-child {
        border-top-right-radius: 4px;
    }
    tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
    }
    tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
    }

    tr:first-child th { border-top-style: solid; }
    tr td:first-child { border-left-style: solid; }
    tr th:first-child { border-left-style: solid; }

    td, th {
        text-align: center;
    }

    th {
        padding: 8px;
    }

    td {
        font-size: 0.9em;
        padding: 5px;
        background-color: #f0f1f4;
    }

    th, .tr-total td {
        background-color: #383a4c;
        color: white;
        font-size: 1em;
    }

    td:has(input) {
        background-color: #def1de;
    }

    #div-total {
        margin-right: 60%;
        margin-bottom: 40px;

        td:first-child {
            font-weight: bold;
            font-size: 1em;
        }
    }

    #div-paiement {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 40px;


        #btn-paiement {
            background-color: #5cb85c;
            margin-left: 0 !important;
            padding: 10px 24px;
            font-weight: bold;
            font-size: 1.25em;
        }
    }

    .table-montant-libre {
        margin-top: 16px;
        margin-left: 80%;
        width: 20%;

        td {
            text-align: center;
            font-size: 1em;

            input {
                width: 90px;
                padding: 2px
            }
        }

        tr {
            font-weight: bold;
        }
    }

    @media (max-width: 600px) {
        .hideOnMobile {
            display: none;
        }

        #div-total {
            margin-left: 0;
        }

        .table-montant-libre {
            margin-left: 66%;
            width: 34%;
        }
    }
}
