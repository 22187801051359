.table-avancement-devoirs {
	border-collapse: collapse;
	margin-top: 30px;

	.nom-formation.more, .colis.selected {
		i.rotate {
			transform:rotate(180deg);
		}
	}

	.nom-formation, .colis {
		i.rotate {
			transition: all 0.5s ease;
		}
	}

	.hidden {
		display: none !important;
	}

	.avancement-formation-head {
		width: 100%;
		vertical-align: middle;

		font-weight: normal;
		margin: 0;
		width: 100%;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		display: inline-block;

		.noms-colis {
			padding: 0;
		}

		h2 {
			margin: 0;
		}

		h3 {
			margin: 0;
		}

		.colis {
			padding: 10px;
			padding-bottom: 3px;
			text-align: center;
			margin: 0;
			float: left;

			border-style: solid;
			border-top-style: none;
			border-left-width: 0px;
			border-bottom-width: 1px;
			border-right-width: 1px;

			h3 {
				display: inline;
			}

			i {
				vertical-align: top;
				padding-top: 3px;
				margin-left: 5px;
			}
		}

		.colis.selected {
			padding: 8px;
			padding-bottom: 1px;
			padding-top: 10px;
			border-bottom-width: 3px;
			border-left-width: 2px;
			border-right-width: 3px;

		}

		.colis:first-child {

			border-left-width: 1px !important;
			margin-left: 0;
		}

		.colis.selected:first-child {
			border-left-width: 3px !important;
		}

		.nom-formation {
			padding: 10px;
			border: none;
			width: 100%;

			h1 {
				display: inline-block;
				margin: 0;
			}

			a:hover {
				cursor: pointer;
			}

			i {
				display: inline-block;
				float: right;
				font-size: 30px;
				color: white;
			}
		}

		.nom-formation:hover {
			cursor: pointer;
		}

		.noms-colis {
			box-shadow: 0px 0px 3px #555;

			.colis:hover {
				cursor: pointer;
			}
		}

	}

}

.avancement-formation-body {
	width: 100%;
	vertical-align: middle;
	text-align: left;

	.row {
		margin: 0;
	}

	.discipline.full:hover {
		cursor: pointer;
	}

	.discipline {
		height: 50px;
		vertical-align: middle;
		border-bottom: 1px solid #B4B4B4;

		.libelle-discipline {
			display: inline-block;
			float: left;
		}

		.bouton-devoirs {
			float: right;
			padding: 5px 10px;
			margin: 0;
		}
	}

	.devoir {
		border-bottom: 1px solid #B4B4B4;

		div {
			height: 100%;
			padding: 15px 10px 0 10px;
		}

		.titre {
			font-weight: bold;
		}

		.note-devoir {
			text-align: center;
		}

		.infos-correction {

			.date-correction {
				display: inline;
			}
		}

		.commentaire-professeur {
			position: relative;
			font-size: 12px ;
			min-height: 80px;
		}

		.commentaire-professeur:hover {
			cursor: text;
		}

		.commentaire-professeur:before {
			content: "";
			position: absolute;
			left: 0;
			top: 15%;
			height: 70%;
			width: 100%;
			border-left: 2px solid #CDCDCD;
			border-right: 2px solid #CDCDCD;
		}
	}

	.discipline {
		padding: 10px;
		span {
			padding-top: 5px;
		}
	}
}

@media only screen and (min-width: 992px) {
	.table-avancement-devoirs {
		margin: 40px auto;
		width: 80%;
	}

}

@media only screen and (max-width: 992px) {
	.table-avancement-devoirs {
		.avancement-formation-head {
			margin: 0;
			.nom-formation {
				h1, i {
					font-size: 18px;
					padding: 5px 0;
				}
			}
		}

		.avancement-formation-body {
			.colis {
				height: 50px;
				width: 100%;
				border-bottom-style: solid;
				border-bottom-width: 1px;
				text-align: center;
				padding: 15px 10px 10px 0;

				&.non-recu{
					padding-right: 30px;
				}

				h3 {
					margin: 0;
					text-align: center;
					font-size: 20px;
					display: inline-block;
				}

				i {
					display: inline-block;
					float: right;
					font-size: 20px;
				}
			}

			.discipline {
				height: 50px;
				background: none !important;

				.libelle-discipline {
					span {
						display: none;
					}
				}
			}

			.disciplines-de-colis:after {
				box-shadow: 0px 4px #000;
			}

			.devoir {
				color: #676978 !important;
				padding-bottom: 10px;

				.titre {
					text-align: left;
				}

				.note-devoir {
					display: inline-block;
					float: right;
					margin-top: -37px;

					br {
						display: none;
					}
				}

				.commentaire-professeur {
					width: 100%;
					font-size: 14px;
				}

				.commentaire-professeur:before {
					display: none;
				}

				.infos-correction {
					display: none;
				}
			}
		}
	}
}