.footer
{
	padding-bottom: 35px;
    border-top-width: 1px;
    border-top-style: solid;
	position: absolute;
	bottom: 0px;
	clear: both;
	min-height: 25px;
	margin: auto;
	width: 100%;

	li
	{
		list-style: none;
		display: inline-block;

		a
		{
			display: inline-block;
			text-decoration: none;
			line-height: 1.2rem;
			padding: 8px 30px 0px 30px;
			font-size: 12px;
		}
	}

	ul, p
	{
		text-align: center;
		margin: 10px 10px 0 10px;
		padding: 0;
	}

	.reseaux-sociaux {

		li {

			a {
				width: 27px;
				line-height: 27px;
				padding: 0;
				text-align: center;
				margin: 0 2px;
				font-size: 17px;
			}
			a:hover {
				color: white;
			}
		}
	}
}
footer{
	&.not-absolute{
		.footer{
			position: relative;
		}
	}
}
@media only screen and (max-width: 992px)
{

	.liens {

		 li {
		 	display: block;
		}
	}
}