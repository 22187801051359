tr {
	.btn-download-document {
		background: $blue;
		color: $white;
		border-color: $blue;

	}

	&.downloaded  {
		td:first-child {
			border-left: 6px solid $blue !important;
		}

		.btn-download-document {
			background: $white;
			color: $blue;
			border-color: $blue;
		}
	}
}
