.legende-professeur{
	display: block;
	width: 200px;
	position: absolute;
	left: -250px;
	top: 150px;

	.titre-legende {
		font-weight : bold;
		background : white;
		text-align: center;
		border: 1px solid grey;
	}

	.legende-color,
	.titre-legende {
		height: 40px;
		line-height: 40px;
		i {
			color:#1e5983;
			font-size: 20px;
		}
		&.interne {
			background-color:#a0a0a0;
		}
	}
	.legende-border{
		border: 1px solid grey;
	}
	.legende-action{
		background : white;
		.legende-color {
			display: inline-block;
			width: 40px;
			vertical-align: middle;
			border-right: 1px dotted grey;
			i {
				vertical-align: middle;
			}
		}
	}
}

.comptes-actifs {
	position: relative;
	.professeurs-inactifs, .professeurs-actifs {

		.professeur {
			display: inline-block;
			width: 200px;
			padding: 10px;
			margin: 9px;
			border-radius: 5px;
			position: relative;

			.is-premium {
				color: #1e5983;
				font-size: 45px;
				position: absolute;
				top: 0;
				right: 0;
			}

			.avatar-professeur {
				width: 90%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 10px;

				img {
					width: 100%;
					border-radius: 5px;
				}
			}

			.infos-professeur {
				text-align: center;
				height: 45px;
			}

			.toggle-compte {
				text-align: center;

				.bouton {
					padding: 8px 10px;
				}
			}
		}
	}
}