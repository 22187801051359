.tableau-devoirs-professeur {
	width: 98%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;

	.titres {

		th {
			text-align: center;
		}
	}
	.trie-entete .fa {
	    position: absolute;
	    right: -15px;
	    font-size: 18px;
	    top: 0;
	}

	.titre {
		padding : 5px 0;
	}
	.body {
		tr {
			border-bottom: 1px solid lightgrey;
			&:nth-child(odd) {
		      background-color: #f7f7f7;
		      border-top-width: 1px;
		      border-bottom-width: 1px;
		      border-top-style: solid;
		      border-bottom-style: solid;
	    	}
			td {
			 	padding: 10px;
			 	.bouton {
			 		margin-bottom: 10px;
			 	}
			}

			.eleve {
				font-size: 12px;

				.nom-eleve {
					font-weight: bold;
				}

				> div {
					margin-bottom: 10px;
				}
			}
			.type-copie {
				max-width: 95px;
			}

			.copie-eleve {
				max-width: 145px;
			}

			.correction {
				max-width: 150px;

				.correction-papier {
					div.note {
						display: inline-block;
						width: 89px
					}

					.ok {
						margin-top: 7px;
					}
				}
			}

			.etat {
				.nom-professeur {
					font-size: 12px;
					font-weight: bold;
				}
			}

			.date-affectation {
				min-width: 128px;
			}

			.copie-eleve, .sujet, .correction {
				& .bouton-dual-color-light {
					border: 1px solid #afafaf;
				}
			}
		}
	}
}

.popin {

	.historique-copies-eleve {
		text-align: left;
		overflow: auto;

		.item {
			overflow: auto;
			margin-bottom: 20px;
			border-bottom: solid 1px lightgrey;
			position: relative;

			> div {
				padding: 10px;
			}

			.infos-devoir {
				width: 20%;
				float: left;
				.bouton {
					margin: 5px 0;
				}
			}

			.commentaire-eleve {
				width: 30%;
				float: left;
			}

			.commentaire-professeur {
				width: 29%;
				float: left;
			}

			.infos-correction {
				width: 20%;
				float: left;
				padding-left: 20px;

				.bouton {
					margin: 5px 0;
				}
			}

			.infos-correction:before {
				content: "";
				position: absolute;
				top: 10%;
				left: 50%;
				height: 80%;
				border-left: 1px solid lightgrey;
			}
		}
	}

	.depot-correction-professeur {
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		.etat, .note, .fichier {
			margin-bottom: 20px;
		}

		.etat, .note {
			width: 135px;
			display: inline-block;

		}

		.note {
			margin-left: 20px;
		}

		.input-commentaire-professeur {
			width: 300px;
			max-width: 550px;
			height: 100px;
			display: inline-block;
			margin-left: auto;
			margin-right: auto;
			margin: 20px 0;
		}

		.avancement-devoirs {
			margin-top: 40px;
			margin-bottom: 20px;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.modification-correction-professeur {

		.tiers {
			width: 33%;
			display: inline-block;
			vertical-align: top;
			padding: 10px 10px;

			h3 {
				margin-bottom: 20px;
			}

			.submit-form {
				padding-top: 10px;
				margin-top: 10px;
			}
		}

	}
}



.formulaire-recherche-devoirs {

	.left {
		width: 300px;
		display: inline-block;
		vertical-align:  top;
	}

	.right {
		width: 300px;
		margin-left: calc(50% - 300px);
		display: inline-block;
		vertical-align:  top;
	}

	.bouton {
		display: block;
		padding: 7px 15px;
		text-align: center;
		margin-top: 20px;
	}
}
